import { default as _403llK5nApRneMeta } from "/var/www/carpot.com.ua/pages/403.vue?macro=true";
import { default as about_45usJy6CYjNSPsMeta } from "/var/www/carpot.com.ua/pages/about-us.vue?macro=true";
import { default as indexpawnLboGPzMeta } from "/var/www/carpot.com.ua/pages/admin/cars/[brand]/index.vue?macro=true";
import { default as indexU5IwVuwRp5Meta } from "/var/www/carpot.com.ua/pages/admin/cars/index.vue?macro=true";
import { default as companiesYQOV9J5YQCMeta } from "/var/www/carpot.com.ua/pages/admin/companies.vue?macro=true";
import { default as index8ZMgWhelVJMeta } from "/var/www/carpot.com.ua/pages/admin/index.vue?macro=true";
import { default as index8HXNv4tjysMeta } from "/var/www/carpot.com.ua/pages/admin/parts/index.vue?macro=true";
import { default as requestsUpovJmEwYWMeta } from "/var/www/carpot.com.ua/pages/admin/requests.vue?macro=true";
import { default as reviewsycVokS7tjuMeta } from "/var/www/carpot.com.ua/pages/admin/reviews.vue?macro=true";
import { default as users0mmXUAz1APMeta } from "/var/www/carpot.com.ua/pages/admin/users.vue?macro=true";
import { default as _91car_45id_93QTcBgvAGrdMeta } from "/var/www/carpot.com.ua/pages/cars/[car-id].vue?macro=true";
import { default as indexepGg9k8Z98Meta } from "/var/www/carpot.com.ua/pages/dashboard/index.vue?macro=true";
import { default as _91id_93jGA6UO5O4rMeta } from "/var/www/carpot.com.ua/pages/dashboard/my-cars/[id].vue?macro=true";
import { default as addC8WJUdh80vMeta } from "/var/www/carpot.com.ua/pages/dashboard/my-cars/add.vue?macro=true";
import { default as indexsStXhXDPNCMeta } from "/var/www/carpot.com.ua/pages/dashboard/my-cars/index.vue?macro=true";
import { default as discountsnC1IjvVe9eMeta } from "/var/www/carpot.com.ua/pages/discounts.vue?macro=true";
import { default as forgot_45passwordHqenQRREJTMeta } from "/var/www/carpot.com.ua/pages/forgot-password.vue?macro=true";
import { default as indexqGDLoBbSZSMeta } from "/var/www/carpot.com.ua/pages/index.vue?macro=true";
import { default as loginHEU4ShLoAjMeta } from "/var/www/carpot.com.ua/pages/login.vue?macro=true";
import { default as carCyEfk0iyQiMeta } from "/var/www/carpot.com.ua/pages/my-requests/[id]/car.vue?macro=true";
import { default as editEB8u6dJXvEMeta } from "/var/www/carpot.com.ua/pages/my-requests/[id]/edit.vue?macro=true";
import { default as indexWvmVooSMPzMeta } from "/var/www/carpot.com.ua/pages/my-requests/[id]/index.vue?macro=true";
import { default as confirm_45requestCYdSYsTunHMeta } from "/var/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/confirm-request.vue?macro=true";
import { default as indexflxxezqKJZMeta } from "/var/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/index.vue?macro=true";
import { default as send_45reviewdyH0v31J2bMeta } from "/var/www/carpot.com.ua/pages/my-requests/[id]/send-review.vue?macro=true";
import { default as indexQEUw9hMF70Meta } from "/var/www/carpot.com.ua/pages/my-requests/index.vue?macro=true";
import { default as change_45aboutbqa7uTDMqJMeta } from "/var/www/carpot.com.ua/pages/my-shop/change-about.vue?macro=true";
import { default as change_45addressmm4YVmtLHEMeta } from "/var/www/carpot.com.ua/pages/my-shop/change-address.vue?macro=true";
import { default as change_45emailjGIx36JFHHMeta } from "/var/www/carpot.com.ua/pages/my-shop/change-email.vue?macro=true";
import { default as change_45nameetJ11aJJRdMeta } from "/var/www/carpot.com.ua/pages/my-shop/change-name.vue?macro=true";
import { default as change_45phonepZuDNTHhn5Meta } from "/var/www/carpot.com.ua/pages/my-shop/change-phone.vue?macro=true";
import { default as change_45websiteU4QjsS7WDmMeta } from "/var/www/carpot.com.ua/pages/my-shop/change-website.vue?macro=true";
import { default as changeKjnk1zZJVLMeta } from "/var/www/carpot.com.ua/pages/my-shop/change.vue?macro=true";
import { default as _91delivery_932VcBJTfbhGMeta } from "/var/www/carpot.com.ua/pages/my-shop/custom-delivery/[delivery].vue?macro=true";
import { default as create2kXj9gvXpEMeta } from "/var/www/carpot.com.ua/pages/my-shop/custom-delivery/create.vue?macro=true";
import { default as _91payment_93lkhLhNJZciMeta } from "/var/www/carpot.com.ua/pages/my-shop/custom-payment-method/[payment].vue?macro=true";
import { default as createZA7WrbiPCgMeta } from "/var/www/carpot.com.ua/pages/my-shop/custom-payment-method/create.vue?macro=true";
import { default as _91delivery_93pevARGcI6VMeta } from "/var/www/carpot.com.ua/pages/my-shop/delivery/[delivery].vue?macro=true";
import { default as _91brandId_93lqMGyhCzqEMeta } from "/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/brands/[brandId].vue?macro=true";
import { default as indexWOjSA7W0x1Meta } from "/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/index.vue?macro=true";
import { default as _91modelId_93BV3PrY7E2mMeta } from "/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/models/[modelId].vue?macro=true";
import { default as _91partId_93NyNXLDo9VLMeta } from "/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/[partId].vue?macro=true";
import { default as indexSi59QKv5FBMeta } from "/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/index.vue?macro=true";
import { default as indexOxIeBgRytlMeta } from "/var/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/index.vue?macro=true";
import { default as indexu7T9qjOIC4Meta } from "/var/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/model/[model]/index.vue?macro=true";
import { default as indexfX02ufV5PjMeta } from "/var/www/carpot.com.ua/pages/my-shop/filters/index.vue?macro=true";
import { default as indexf3v1XmF0VRMeta } from "/var/www/carpot.com.ua/pages/my-shop/index.vue?macro=true";
import { default as indexcrEe0XoTi5Meta } from "/var/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/index.vue?macro=true";
import { default as send_45offerXhg0CjmxuyMeta } from "/var/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-offer.vue?macro=true";
import { default as send_45reviewH88s8Wj3BDMeta } from "/var/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-review.vue?macro=true";
import { default as indexlrZ5fjzkjwMeta } from "/var/www/carpot.com.ua/pages/my-shop/my-requests/index.vue?macro=true";
import { default as _91payment_93ziTMLqqHPgMeta } from "/var/www/carpot.com.ua/pages/my-shop/payment-method/[payment].vue?macro=true";
import { default as responsexRxDY3HuBrMeta } from "/var/www/carpot.com.ua/pages/my-shop/response.vue?macro=true";
import { default as settingsrCRbRIMDdAMeta } from "/var/www/carpot.com.ua/pages/my-shop/settings.vue?macro=true";
import { default as notificationsSHYXc1AT5vMeta } from "/var/www/carpot.com.ua/pages/notifications.vue?macro=true";
import { default as become_45sellerdwY0d2niupMeta } from "/var/www/carpot.com.ua/pages/partners/become-seller.vue?macro=true";
import { default as index7KqhP8MOWaMeta } from "/var/www/carpot.com.ua/pages/partners/index.vue?macro=true";
import { default as _91id_93RE7dnkJfWZMeta } from "/var/www/carpot.com.ua/pages/parts/[id].vue?macro=true";
import { default as _91hash_93vnrOQg5PznMeta } from "/var/www/carpot.com.ua/pages/password-reset/[hash].vue?macro=true";
import { default as change_45nameVBLI3kCXPJMeta } from "/var/www/carpot.com.ua/pages/profile/change-name.vue?macro=true";
import { default as change_45phoneuspENMpfREMeta } from "/var/www/carpot.com.ua/pages/profile/change-phone.vue?macro=true";
import { default as index0z6iNtdwwVMeta } from "/var/www/carpot.com.ua/pages/profile/index.vue?macro=true";
import { default as registerV8asqvt2qOMeta } from "/var/www/carpot.com.ua/pages/register.vue?macro=true";
import { default as requestOPYdssbEm3Meta } from "/var/www/carpot.com.ua/pages/request.vue?macro=true";
import { default as change_45emailCGSxL8rimzMeta } from "/var/www/carpot.com.ua/pages/settings/change-email.vue?macro=true";
import { default as change_45passwordQQjnSp87HpMeta } from "/var/www/carpot.com.ua/pages/settings/change-password.vue?macro=true";
import { default as indexkpGY97BiBmMeta } from "/var/www/carpot.com.ua/pages/settings/index.vue?macro=true";
import { default as indexyncE5g953WMeta } from "/var/www/carpot.com.ua/pages/shop/[id]/index.vue?macro=true";
import { default as ratingfPqzoHv4XiMeta } from "/var/www/carpot.com.ua/pages/shop/[id]/rating.vue?macro=true";
import { default as indexJkOM3l1M9bMeta } from "/var/www/carpot.com.ua/pages/support/index.vue?macro=true";
import { default as _91id_93WoGIL7CcEvMeta } from "/var/www/carpot.com.ua/pages/transactions/[id].vue?macro=true";
import { default as indexnx1NhadHxzMeta } from "/var/www/carpot.com.ua/pages/transactions/index.vue?macro=true";
import { default as failurevX2akNHHf8Meta } from "/var/www/carpot.com.ua/pages/wallet/failure.vue?macro=true";
import { default as indexJi5WJCAsMCMeta } from "/var/www/carpot.com.ua/pages/wallet/index.vue?macro=true";
import { default as infoWCVSmmtwxGMeta } from "/var/www/carpot.com.ua/pages/wallet/info.vue?macro=true";
import { default as transactionsU2CTgeEJq9Meta } from "/var/www/carpot.com.ua/pages/wallet/transactions.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    component: () => import("/var/www/carpot.com.ua/pages/403.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/var/www/carpot.com.ua/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "admin-cars-brand",
    path: "/admin/cars/:brand()",
    meta: indexpawnLboGPzMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/cars/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cars",
    path: "/admin/cars",
    meta: indexU5IwVuwRp5Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/cars/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-companies",
    path: "/admin/companies",
    meta: companiesYQOV9J5YQCMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/companies.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: index8ZMgWhelVJMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-parts",
    path: "/admin/parts",
    meta: index8HXNv4tjysMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-requests",
    path: "/admin/requests",
    meta: requestsUpovJmEwYWMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/requests.vue").then(m => m.default || m)
  },
  {
    name: "admin-reviews",
    path: "/admin/reviews",
    meta: reviewsycVokS7tjuMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/reviews.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: users0mmXUAz1APMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "cars-carid",
    path: "/cars/:carid()",
    component: () => import("/var/www/carpot.com.ua/pages/cars/[car-id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexepGg9k8Z98Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-cars-id",
    path: "/dashboard/my-cars/:id()",
    meta: _91id_93jGA6UO5O4rMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/dashboard/my-cars/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-cars-add",
    path: "/dashboard/my-cars/add",
    meta: addC8WJUdh80vMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/dashboard/my-cars/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-cars",
    path: "/dashboard/my-cars",
    meta: indexsStXhXDPNCMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/dashboard/my-cars/index.vue").then(m => m.default || m)
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/var/www/carpot.com.ua/pages/discounts.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordHqenQRREJTMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/carpot.com.ua/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginHEU4ShLoAjMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-car",
    path: "/my-requests/:id()/car",
    meta: carCyEfk0iyQiMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/[id]/car.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-edit",
    path: "/my-requests/:id()/edit",
    meta: editEB8u6dJXvEMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id",
    path: "/my-requests/:id()",
    meta: indexWvmVooSMPzMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-request-offer-requestofferid-confirm-request",
    path: "/my-requests/:id()/request-offer/:requestofferid()/confirm-request",
    meta: confirm_45requestCYdSYsTunHMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/confirm-request.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-request-offer-requestofferid",
    path: "/my-requests/:id()/request-offer/:requestofferid()",
    meta: indexflxxezqKJZMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[requestofferid]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-send-review",
    path: "/my-requests/:id()/send-review",
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/[id]/send-review.vue").then(m => m.default || m)
  },
  {
    name: "my-requests",
    path: "/my-requests",
    meta: indexQEUw9hMF70Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-about",
    path: "/my-shop/change-about",
    meta: change_45aboutbqa7uTDMqJMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change-about.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-address",
    path: "/my-shop/change-address",
    meta: change_45addressmm4YVmtLHEMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change-address.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-email",
    path: "/my-shop/change-email",
    meta: change_45emailjGIx36JFHHMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change-email.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-name",
    path: "/my-shop/change-name",
    meta: change_45nameetJ11aJJRdMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change-name.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-phone",
    path: "/my-shop/change-phone",
    meta: change_45phonepZuDNTHhn5Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change-phone.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-website",
    path: "/my-shop/change-website",
    meta: change_45websiteU4QjsS7WDmMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change-website.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change",
    path: "/my-shop/change",
    meta: changeKjnk1zZJVLMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/change.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-delivery-delivery",
    path: "/my-shop/custom-delivery/:delivery()",
    meta: _91delivery_932VcBJTfbhGMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/custom-delivery/[delivery].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-delivery-create",
    path: "/my-shop/custom-delivery/create",
    meta: create2kXj9gvXpEMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/custom-delivery/create.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-payment-method-payment",
    path: "/my-shop/custom-payment-method/:payment()",
    meta: _91payment_93lkhLhNJZciMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/custom-payment-method/[payment].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-payment-method-create",
    path: "/my-shop/custom-payment-method/create",
    meta: createZA7WrbiPCgMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/custom-payment-method/create.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-delivery-delivery",
    path: "/my-shop/delivery/:delivery()",
    meta: _91delivery_93pevARGcI6VMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/delivery/[delivery].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-filterId-cars-brands-brandId",
    path: "/my-shop/filters/:filterId()/cars/brands/:brandId()",
    meta: _91brandId_93lqMGyhCzqEMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/brands/[brandId].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-filterId-cars",
    path: "/my-shop/filters/:filterId()/cars",
    meta: indexWOjSA7W0x1Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-filterId-cars-models-modelId",
    path: "/my-shop/filters/:filterId()/cars/models/:modelId()",
    meta: _91modelId_93BV3PrY7E2mMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/cars/models/[modelId].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-filterId-parts-partId",
    path: "/my-shop/filters/:filterId()/parts/:partId()",
    meta: _91partId_93NyNXLDo9VLMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/[partId].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-filterId-parts",
    path: "/my-shop/filters/:filterId()/parts",
    meta: indexSi59QKv5FBMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/[filterId]/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-add-filter-brand-brand",
    path: "/my-shop/filters/add-filter/brand/:brand()",
    meta: indexOxIeBgRytlMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters-add-filter-brand-brand-model-model",
    path: "/my-shop/filters/add-filter/brand/:brand()/model/:model()",
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/add-filter/brand/[brand]/model/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-filters",
    path: "/my-shop/filters",
    meta: indexfX02ufV5PjMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/filters/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop",
    path: "/my-shop",
    meta: indexf3v1XmF0VRMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests-sellerrequestid",
    path: "/my-shop/my-requests/:sellerrequestid()",
    meta: indexcrEe0XoTi5Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests-sellerrequestid-send-offer",
    path: "/my-shop/my-requests/:sellerrequestid()/send-offer",
    meta: send_45offerXhg0CjmxuyMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-offer.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests-sellerrequestid-send-review",
    path: "/my-shop/my-requests/:sellerrequestid()/send-review",
    meta: send_45reviewH88s8Wj3BDMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/my-requests/[sellerrequestid]/send-review.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests",
    path: "/my-shop/my-requests",
    meta: indexlrZ5fjzkjwMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-payment-method-payment",
    path: "/my-shop/payment-method/:payment()",
    meta: _91payment_93ziTMLqqHPgMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/payment-method/[payment].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-response",
    path: "/my-shop/response",
    meta: responsexRxDY3HuBrMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/response.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-settings",
    path: "/my-shop/settings",
    meta: settingsrCRbRIMDdAMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/my-shop/settings.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsSHYXc1AT5vMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "partners-become-seller",
    path: "/partners/become-seller",
    meta: become_45sellerdwY0d2niupMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/partners/become-seller.vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/var/www/carpot.com.ua/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-id",
    path: "/parts/:id()",
    component: () => import("/var/www/carpot.com.ua/pages/parts/[id].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-hash",
    path: "/password-reset/:hash()",
    meta: _91hash_93vnrOQg5PznMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/password-reset/[hash].vue").then(m => m.default || m)
  },
  {
    name: "profile-change-name",
    path: "/profile/change-name",
    meta: change_45nameVBLI3kCXPJMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/profile/change-name.vue").then(m => m.default || m)
  },
  {
    name: "profile-change-phone",
    path: "/profile/change-phone",
    meta: change_45phoneuspENMpfREMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/profile/change-phone.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: index0z6iNtdwwVMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerV8asqvt2qOMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "request",
    path: "/request",
    meta: requestOPYdssbEm3Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/request.vue").then(m => m.default || m)
  },
  {
    name: "settings-change-email",
    path: "/settings/change-email",
    meta: change_45emailCGSxL8rimzMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/settings/change-email.vue").then(m => m.default || m)
  },
  {
    name: "settings-change-password",
    path: "/settings/change-password",
    meta: change_45passwordQQjnSp87HpMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/settings/change-password.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexkpGY97BiBmMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-id",
    path: "/shop/:id()",
    meta: indexyncE5g953WMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/shop/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-id-rating",
    path: "/shop/:id()/rating",
    meta: ratingfPqzoHv4XiMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/shop/[id]/rating.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/var/www/carpot.com.ua/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "transactions-id",
    path: "/transactions/:id()",
    meta: _91id_93WoGIL7CcEvMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/transactions/[id].vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: indexnx1NhadHxzMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-failure",
    path: "/wallet/failure",
    meta: failurevX2akNHHf8Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/wallet/failure.vue").then(m => m.default || m)
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: indexJi5WJCAsMCMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-info",
    path: "/wallet/info",
    meta: infoWCVSmmtwxGMeta || {},
    component: () => import("/var/www/carpot.com.ua/pages/wallet/info.vue").then(m => m.default || m)
  },
  {
    name: "wallet-transactions",
    path: "/wallet/transactions",
    meta: transactionsU2CTgeEJq9Meta || {},
    component: () => import("/var/www/carpot.com.ua/pages/wallet/transactions.vue").then(m => m.default || m)
  }
]